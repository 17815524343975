import React, { Component } from "react";
import { Helmet } from "react-helmet";
import logo from "./images/logoFull.png";
import Geosuggest from "react-geosuggest";
import Select from "react-select";

import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import "firebase/compat/functions";
import { withTranslation } from "react-i18next";
import firebase from "firebase/compat/app";

class RegBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: "",
      address: "",
      title: "",
      number: "",
      selectedCat: [],
      errorText: "",
    };
    if (firebase.auth().currentUser == null) {
      this.props.history.push("/login");
    }
  }

  getLocation(suggest) {
    if (suggest != undefined) {
      this.setState({
        location: suggest.location,
        address: suggest.label,
      });
    }
  }

  addBar() {
    if (
      this.state.location !== "" &&
      this.state.address !== "" &&
      this.state.title !== "" &&
      this.state.number !== ""
    ) {
      console.log("IN===>");
      let categories = [];
      this.state.selectedCat.forEach((cat) => {
        categories = [...categories, cat.value];
      });
      const uid = firebase.auth().currentUser.uid;
      let ref = firebase
        .firestore()
        .collection("bars")
        .doc(uid + "1");
      ref.set({
        adress: this.state.address,
        category: categories,
        coordinates: {
          latitude: this.state.location.lat,
          longitude: this.state.location.lng,
        },
        events: [],
        hours: {
          monday: "00:00-00:00",
          tuesday: "00:00-00:00",
          wednesday: "00:00-00:00",
          thursday: "00:00-00:00",
          friday: "00:00-00:00",
          saturday: "00:00-00:00",
          sunday: "00:00-00:00",
        },
        id: uid + "1",
        link: "",
        name: this.state.title,
        number: this.state.number,
        email: firebase.auth().currentUser.email,
        facebook: "",
        instagram: "",
        food: false,
        student: false,
        created: new Date(),
        trial: true,
        subscription: 0,
      });
      ref = firebase.firestore().collection("owners").doc(uid);
      ref.update({
        bars: [uid + "1"],
      });
      this.props.history.push("/dashboard");
    } else {
      this.setState({ errorText: "Everything is not filled in correctly." });
    }
  }

  render() {
    const { t } = this.props;
    const options = [
      { value: t("regBar.bar"), label: t("regBar.bar") },
      { value: t("regBar.pub"), label: t("regBar.pub") },
      { value: t("regBar.cafe"), label: t("regBar.cafe") },
      { value: t("regBar.bistro"), label: t("regBar.bistro") },
      { value: t("regBar.restaurant"), label: t("regBar.restaurant") },
      { value: t("regBar.lounge"), label: t("regBar.lounge") },
      { value: t("regBar.bodega"), label: t("regBar.bodega") },
      { value: t("regBar.wineBar"), label: t("regBar.wineBar") },
      { value: t("regBar.gastroPub"), label: t("regBar.gastroPub") },
      { value: t("regBar.cocktailBar"), label: t("regBar.cocktailBar") },
      { value: t("regBar.latinoBar"), label: t("regBar.latinoBar") },
      { value: t("regBar.karaoke"), label: t("regBar.karaoke") },
      { value: t("regBar.jazzBar"), label: t("regBar.jazzBar") },
      { value: t("regBar.rockBar"), label: t("regBar.rockBar") },
      { value: t("regBar.pianoBar"), label: t("regBar.pianoBar") },
      { value: t("regBar.bowling"), label: t("regBar.bowling") },
      { value: t("regBar.beachBar"), label: t("regBar.beachBar") },
      { value: t("regBar.disco"), label: t("regBar.disco") },
      { value: t("regBar.champagneBar"), label: t("regBar.champagneBar") },
      { value: t("regBar.gayBar"), label: t("regBar.gayBar") },
      { value: t("regBar.gentlemensBar"), label: t("regBar.gentlemensBar") },
      { value: t("regBar.ladiesBar"), label: t("regBar.ladiesBar") },
      { value: t("regBar.libertine"), label: t("regBar.libertine") },
      { value: t("regBar.poolTables"), label: t("regBar.poolTables") },
      { value: t("regBar.gameBar"), label: t("regBar.gameBar") },
      { value: t("regBar.distillery"), label: t("regBar.distillery") },
      { value: t("regBar.brewerie"), label: t("regBar.brewerie") },
      { value: t("regBar.comedyBar"), label: t("regBar.comedyBar") },
      { value: t("regBar.cabaret"), label: t("regBar.cabaret") },
      { value: t("regBar.hotelBar"), label: t("regBar.hotelBar") },
      { value: t("regBar.casino"), label: t("regBar.casino") },
      { value: t("regBar.rooftopBar"), label: t("regBar.rooftopBar") },
    ];
    return (
      <div>
        <Helmet>
          <style>
            {
              ".css-1wa3eu0-placeholder{font-weight: 300 !important; font-size: 10pt !important; }.css-yk16xz-control{border:none !important; border-radius: 2px !important;}.geosuggest__input{color: #7E7E7E !important; font-size: 10pt !important; font-weight: 300 !important; border: none !important;}.css-1hwfws3{padding: 8px !important;}@media screen and (max-width: 1025px){.css-1wa3eu0-placeholder{font-size: 14pt !important;} .geosuggest__input{font-size: 14pt !important}}"
            }
          </style>
        </Helmet>
        <div className="innerReg">
          <img className="logoOnBoarding regLogo" src={logo} alt="logo"></img>
          <p className="regTitle">{t("regBar.regBar")}</p>
          <div className="">
            <input
              className="onBoardingInput"
              value={this.state.title}
              onChange={(event) => this.setState({ title: event.target.value })}
              placeholder={t("regBar.nameBar")}
            ></input>
            <Select
              isMulti
              placeholder={t("regBar.barCat")}
              className="categorySelectOnBoarding"
              value={this.state.selectedCat}
              options={options}
              onChange={(selected) => {
                this.setState({ selectedCat: selected });
              }}
            />
            <Geosuggest
              placeholder={t("regBar.searchPlaces")}
              onSuggestSelect={(suggest) => this.getLocation(suggest)}
            />
            <input
              className="onBoardingInput locationMargin"
              value={this.state.number}
              onChange={(event) =>
                this.setState({ number: event.target.value })
              }
              placeholder={t("regBar.telNumber")}
            ></input>
            <p className="errorText">{this.state.errorText}</p>
          </div>
          <button className="buttonOnBoarding" onClick={() => this.addBar()}>
            {t("regBar.next")}
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(RegBar);
